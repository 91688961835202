// @flow
import React from 'react';

import Layout from '../../../components/Layout/Layout';
import Error from '../../../components/Error/Error';

const SengridError = () => (
  <Layout hasNoHeader hasNoFooter>
    <Error />
  </Layout>
);

export default SengridError;
