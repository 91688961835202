// @flow
import React from 'react';
import cx from 'classnames';
import styles from './Error.module.scss';
import useNavigateBackToTop from '../../hooks/useNavigateBackToTop';

const Error = () => {
  useNavigateBackToTop();

  return (
    <div className={cx(styles.wrapper)}>
      <h2 className={cx(styles.heading, 'paidy-pad-hor-9 paidy-title-01')}>
        エラーが発生しました
      </h2>
    </div>
  );
};

export default Error;
