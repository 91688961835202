import { useEffect } from 'react';
import { navigate } from '@reach/router';

const useNavigateBackToTop = () => {
  useEffect(() => {
    // redirect to top page after 10 seconds
    setTimeout(() => {
      navigate('/');
    }, 10000);
  });
};

export default useNavigateBackToTop;
